<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-n2">
      <h4 style="font-weight: 500">Stockage - Tarification </h4>
    </div>
    <hr class="" />

    <div class="row d-flex justify-content-center">
      <div class="col-sm-4 mb-3" v-for="price in pricing" :key="price.id">
        <div class="card z-depth-1">
          <div class="card-body py-3 px-2 d-flex justify-content-center align-items-center">
            <div class="text-center">
              <h6 class="m-0 mb-3  mt-2" style="font-weight:600">{{price.name}}</h6>
              <h3 style="font-weight:800" class="m-0  mb-3">{{price.nbr_size}} Go</h3>
              <h6>{{price.price  | formatNumber }} <span>FCFA</span></h6>
              <div  class="mt-4">
              <mdb-btn color="primary" size="md" @click="validatePayment(price)" >Choisir ce plan</mdb-btn>
            </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="d-flex justify-content-end"  @click="openModalCustomize()">
          <a href="#"> Faire une offre personnalisée </a>
        </div>
      </div>
    </div>
      <p style="font-size:.8rem;font-weight:500" class="text-danger my-0">* Les frais de transactions sont appliqué par l'agrégateur</p>

      <mdb-modal :show="modalPersonalise.show" @close="modalPersonalise.show = false">
        <mdb-modal-header>
          <mdb-modal-title>Offre Personnalisée</mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body>
          <div class="row">
              <div class="col-md-12">
                <mdb-input type="number" :min="0"  v-model="modalPersonalise.form.size" outline :label="'Nombre de Go'" ></mdb-input>
              </div>
              <div class="col-md-12">
                <table class="table">
                  <tr>
                    <th>Nombre de Giga</th>
                    <td>{{modalPersonalise.form.size}} Go</td>
                  </tr>
                   <tr>
                    <th>Montant</th>
                    <td>{{getAmountCustomize+' FCFA'}} </td>
                  </tr>
                </table>
      <p style="font-size:.8rem;font-weight:500" class="text-danger my-0">* Les frais de transactions sont appliqué par l'agrégateur</p>

              </div>
          </div>
        </mdb-modal-body>
        <mdb-modal-footer>
          <mdb-btn color="grey"  size="md" @click.native="modalPersonalise.show = false">Annuler</mdb-btn>
          <mdb-btn color="primary" size="md"   @click.native="validatePaymentCustomize()">Valider le plan</mdb-btn>
        </mdb-modal-footer>
      </mdb-modal>
  </div>
</template>
<script>
import {
  mdbBtn,
  mdbModal,
      mdbModalHeader,
      mdbModalTitle,
      mdbModalBody,
      mdbModalFooter,
      mdbInput,
} from "mdbvue";
export default {
  name: "sub-setting-storage",
  components: {
    mdbBtn,
    mdbModal,
      mdbModalHeader,
      mdbModalTitle,
      mdbModalBody,
      mdbModalFooter,
      mdbInput,
  },
  data() {
    return {
      pricing: [],
      modalSub: {
        show: false,
      },
      createLance: false,
      amountPrice: 0,
      sites: [],
      siteOption: [],
      registrationStep: 2,
      registrationStepper: [
        { icon: "folder", far: true, name: "Basic Information" },
        { icon: "pencil-alt", name: "Personal Data" },
        { icon: "image", far: true, name: "Terms and Conditions" },
        { icon: "check", name: "Finish" },
      ],
      form: {
        amount: 0,
        customize: 0,
        size: 0,
        storage_pack_id: "",
        transactionId: "",
        account: "",
        company_id: this.$store.state.auth.company.id,
      },
      modalPersonalise: {
        show: false,
        form: {
          amount: 0,
          size: 0,
        }
      }
    };
  },
  methods: {
    closeMSub() {
      this.modalSub.show = false;
      this.loadSites();
    },

    ShowMSub(price) {
      this.amountPrice = price.frequence === "monthly" ? price.amount : price.amount / 12;
      this.form.name = price.name;
      this.form.regime = price.regime;
      this.form.p = price.id;
      this.form.frequence = price.frequence;
      this.form.per_site = price.per_site;
      this.modalSub.show = true;
    },

    async loadStoragePricing() {
      this.$nprogress.start();

      await this.$store
        .dispatch("storage_pack/findPrice")
        .then((response) => {
          this.$nprogress.done();

          this.pricing = response.data.subpaks;
        })
        .catch((error) => {
          this.$nprogress.done();

          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    async loadSites() {
      this.$nprogress.start();

      await this.$store
        .dispatch("site/findAll")
        .then((response) => {
          this.$nprogress.done();
          this.sites = response.data.sites.filter((site) => site.is_subscribed === 0);

          this.sites.forEach((site) => {
            this.siteOption.push({
              text: site.name,
              value: site.id,
            });
          });
        })
        .catch((error) => {
          this.$nprogress.done();
          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    async validatePayment(obj) {
      
      this.form.amount = obj.price
      this.form.storage_pack_id = obj.id
      
      window.openKkiapayWidget({
        amount: this.form.amount,
        position: "right",
        callback: "",
        data: "STORAGETEC",
        sandbox: false,
        theme: "#1A2980",
        key: "df0f4343afe82dc924ebc5a734c9afb006201a41",
      });

      window.addSuccessListener((response) => {
        this.form.transactionId = response.transactionId;
        this.form.account = response.account;

        if (!this.createLance) {
          this.createSub();
        }
      });
    },

    async createSub() {
      this.createLance = true;
      this.$nprogress.start();
      await this.$store
        .dispatch("subscription_space/save", this.form)
        .then(() => {
          this.createLance = false;
          this.$nprogress.done();
          this.$router.push("/parametres/storage");
        })
        .catch((error) => {
          this.createLance = false;
          this.$nprogress.done();
          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    async validatePaymentCustomize() {
       if(parseFloat(this.modalPersonalise.form.size) <= 3){
         return this.$notify({
            message: 'Le nombre de giga doit etre superieur a 3',
            type: "danger",
          });
       }

      this.form.amount = this.modalPersonalise.form.amount
      this.form.storage_pack_id = 0
      this.form.customize =  1
      this.form.size =  this.modalPersonalise.form.size
      
      window.openKkiapayWidget({
        amount: this.form.amount,
        position: "right",
        callback: "",
        data: "STORAGETEC",
        sandbox: false,
        theme: "#1A2980",
        key: "df0f4343afe82dc924ebc5a734c9afb006201a41",
      });

      window.addSuccessListener((response) => {
        this.form.transactionId = response.transactionId;
        this.form.account = response.account;
        if (!this.createLance) {
          this.createSub();
        }
      });
    },

    calculateCustomise(){
      return this.modalPersonalise.form.amount = parseFloat(this.pricing[0].price) * parseFloat(this.modalPersonalise.form.size);
    },

    openModalCustomize(){
      this.modalPersonalise.form.size = 0;
      this.modalPersonalise.form.amount = 0
      this.modalPersonalise.show = true
    }
  },
  created() {
    this.loadSites();
    this.loadStoragePricing();
  },
  computed: {
    getAmountCustomize(){
      return this.calculateCustomise()
    }
  },
};
</script>

<style lang="scss" scoped>
.kkp {
  background-color: rgb(0, 110, 255) !important;

  &.hover {
    background-color: rgb(0, 110, 255) !important;

    border: 1px solid rgb(0, 110, 255);
  }
}

#theme-dark .modal-dialog.modal-notify .modal-body {
  padding: 1.5rem;
  color: white;
}
</style>
<style>
.list-group a:hover,
.list-group button:hover {
  transition: 0.1s;
}

ul.listing0 li {
  margin-top: 15px;
}
</style>
